



































































































































































































































import { getRobotById, GetRobotByIdReq, GetRobotByIdRes} from "@/api/robot";
import { formatDateTwo, jsonParse } from "@/utils/utils";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/utils/eventBus";
import { globalData } from "@/utils/config";
import { Toast } from "vant";
import { CommonRes } from '@/api/common';
import { User } from "@/api/user";
import {
  Fault,
  setFaultState,
  setFaultStateReq,
  Faulteq,
  addRemarks,
  getFault,
  GetFaultByIdRes
} from "@/api/fault";
@Component({
  filters: {
    dealStatusColorFilter(val: number) {
      console.log("dealStatusColorFilter:" + val)
      switch (val) {
        case 0:
          return "stateButtonUn";
        case 1:
          return "stateButtonConf";
        case 2:
          return "stateButtonRe";
        default:
          return "stateButton";
      }
    },
    dealStatusFilter (status: any) {
      switch (status) {
        case 0:{
          return "undetail";
        }
        case 1:{
          return "confirmed";
        }
        case 2:{
          return "resolved";
        }
      }
      return "undetail";
    },
  },
  components: {},
})
export default class Index extends Vue {
  private robotId: string = "";
  private name: string = "";
  private allDuring: number = 0;
  private allArea: number = 0;
  private timeDate: string = formatDateTwo(new Date().getTime() / 1000, "-");
  private currentDate: Date = new Date();
  private minDate: Date = new Date(2000, 0, 1);
  private maxDate: Date = new Date(2035, 10, 1);
  private robot: any = {};
  private left: number = 0;
  private top: number = 0;
  private dxHeight = 1;
  private dxWidth = 1;
  private orgmapwidth: number = screen.width - 22;
  private orgmapheight: number = 340;
  private mapwidth: number = screen.width - 22;
  private mapheight: number = 340;
  private angle: number = 0;
  private robotMap: string = "";
  private remote_address: string = "";
  private isiOSk: boolean = false;
  private fault!: Fault;
  private userInfoStr: string | null = localStorage.getItem("userInfo")
  private userInfo!: User;
  private value1: number = 0;
  private faultContent: string = "";
  private showAddRemark:boolean = false;
  private showStateCon:boolean = false;
  private addRemarkValue: string = "";
  private imgSrc: string = "";
  private show: boolean = false;
  private images: string[] = []
  private index: number = 0
  private option1 = [
    { text: this.$t("undetail"), value: 0 },
    { text: this.$t("confirmed"), value: 1 },
    { text: this.$t("resolved"), value: 2 },
  ]
  
   
  created() {
    if (this.userInfoStr != null) {
      this.userInfo = JSON.parse(this.userInfoStr);
      // this.userInfo.userType
    }
    // EventBus.$on("robotDetail", (msg: string) => {
    //   console.log("msg", msg);
    //   if (msg) {
    //     let json = JSON.parse(msg);
    //     this.robot = json;
    //     this.setRobotJson();
    //   }
    // });
    console.log(this.$route.params);
    let faultdetail = this.$route.params.faultdetail;
    console.log('faultdetail', faultdetail)
    if (faultdetail == undefined || faultdetail == null || faultdetail.length == 0) {
      this.$router.go(-1);
      return
    }
    this.fault = JSON.parse(faultdetail)
    this.value1 = this.fault.state
    this.faultContent = this.fault.remark
    console.log('this.fault.file: ', this.fault.file)
    if (this.fault.file != undefined && this.fault.file.length > 0) {
      let listFiles:string[] = JSON.parse(this.fault.file)
      listFiles.forEach(element => {
        this.images.push(globalData.exportFileUrl + 'request/' + this.fault.robotId + '/' + element)
      });
      this.imgSrc = this.images[0]
      console.log('this.imgSrc ', this.imgSrc)
    }
  }
  private onChange (newIndex: any) {
    this.index = newIndex;
  }
  private imageClick () {
    this.show = true
  }
  private toastText() {
    if (this.robot&& this.robot.location_desc.length > 0) {
      Toast(this.robot.location_desc);
    }
  }
  private ellipsis (value: string): string {
      if (!value) return ''
      if (value.length > 5) {
        return value.slice(0,5) + '...'
      }
      return value
  }
  private setRobotJson() {
    let that = this;
    const map =
      globalData.mapUrl +
      this.robotId +
      globalData.fileUrl +
      this.robot.map_name +
      "/" +
      this.robot.map_name +
      ".png";
    this.robotMap = map;
    const img = new Image() 
    img.src = map;
    img.onload = function() {
      let log = "";
      let nowK = that.orgmapwidth / that.orgmapheight;
      let imgk = img.width / img.height;
      log += "that.mapwidthOOOO :" + that.orgmapwidth  + " that.mapheightOOO:" + that.orgmapheight;
      if (imgk > nowK) {
        that.mapheight = that.mapwidth / imgk;
        console.log("imgkwidth:" + img.width + "imgkheight:" + img.height + " screen.width:" + screen.width + " screen.height:" + screen.height + " nowK:" + nowK);
      } else {
        that.mapwidth = that.mapheight * imgk;
      }
      // that.dxHeight = that.mapheight / that.orgmapwidth;
      // that.dxWidth =  that.mapwidth / that.orgmapheight;
      that.dxHeight = img.height / that.mapheight;
      that.dxWidth = img.width / that.mapwidth;
      log += "that.mapwidthKKK:" + that.mapwidth  + " that.mapheightKKK:" + that.mapheight + " img.width:" + img.width + " img.height:" + img.height + " imgk:" + imgk + " nowK:" + nowK + " dxHeight:" + that.dxHeight + "dxWidth:" + that.dxWidth;
      console.log(log)
      if (that.robot.robot_pose != null) {
      that.left = that.robot.robot_pose.x  / that.dxWidth - 6;
      that.top = that.robot.robot_pose.y / that.dxHeight - 6;
      that.angle = that.robot.robot_pose.theta * 180 / Math.PI + 90;
      console.log(
        "left:" + that.left + ",top:" + that.top + ",angle:" + that.angle
      );
    }
      //imgk.width = screen.width;
      //imgk.height = screen.height;
    }
  }
  private addClick () {
    this.showAddRemark = true
    this.addRemarkValue = ""
  }
  private changeState() {
    this.showStateCon = !this.showStateCon;
  }
  private addRemarkOk () {
    if (this.addRemarkValue != undefined && this.addRemarkValue != '' && this.addRemarkValue.length > 0) {
      this.updateFaultRemark(this.userInfo.username + ":" + this.addRemarkValue);
      this.addRemarkValue = ""
      this.showAddRemark = false
    }
  }
  private addRemarkNo () {
    this.showAddRemark = false
  }
  private async updateFaultState (state: number) {
    let req: setFaultStateReq = {
      state: state,
    };
    let res: CommonRes = await setFaultState(req, this.fault.id);
    console.log("updateFaultState", res);
    if (res.code == 0) {
      this.fault.state = state;
    }
    this.showStateCon = false 
  }
   private async updateFaultRemark (remark: string) {
    let req: Faulteq = {
      id: this.fault.id,
      remark: remark
    };
    let res: CommonRes = await addRemarks(req);
    console.log(res);
    if (res.code == 0) {
      // this.faultContent += "\n" + remark; 
      this.getFault();
    }
  }
  private async getFault() {
    let res: GetFaultByIdRes = await getFault(this.fault.id);
    console.log(res);
    if (res.code == 0) {
      this.faultContent = ''
      this.faultContent = res.fault.remark
      console.log('faultContent:', this.faultContent )
    }
  }
  private listChange (index: number) {
    // Toast('index:' + index)
    this.updateFaultState(index)
  }
  private destroyed() {
    EventBus.$off("robotDetail");
  }
}
